<template>
  <div class="footer_page">
    <div class="footer_content">
      <div class="footer_content_list">
        <!-- <img src="" class="logo"> -->
        <span class="ft50 cl-fff font-600">抖脉</span>
        <div class="title-list ml130">
          <div class="list-title">
            产品
          </div>
          <router-link to="/thesaurus">
            <span class="mt15 cl8b g-flex-row">千川词霸</span>
          </router-link>
          <router-link to="/earlyWarning">
            <span class="mt15 cl8b g-flex-row">千川管家</span>
          </router-link>
          <router-link to="/download">
            <span class="mt15 cl8b g-flex-row">下载</span>
          </router-link>
        </div>
        <div class="title-list ml130">
          <div class="list-title">
            关于
          </div>
          <router-link to="/introduce">
            <span class="mt15 cl8b g-flex-row">关于我们</span>
          </router-link>
        </div>
      </div>
      <div class="g-flex-center">
        <span class="cl8b">© 2021 抖脉&nbsp;-&nbsp;</span>
        <a class="cl8b" target="_blank" href="https://beian.miit.gov.cn/?spm=a2c4g.11186623.J_9220772140.106.398f618fiwL3Sf#/Integrated/index">浙ICP备2021018464号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {},
  methods: {}
}
</script>

<style lang="less" scoped>
.footer_page {
  width: 100%;
  height: 360px;
  background: #161616;
  padding: 38px 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .footer_content {
    width: 100%;
    height: 100%;
    max-width: 1440px;
    padding: 0 18%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .footer_content_list {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }

    .flex-col-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .logo {
      height: 30px;
      width: 40px;
    }

    .title-list {
      display: flex;
      flex-direction: column;
      color: #8b8b8b;
    }

    .list-title {
      font-size: 14px;
      color: #fff;
      margin-bottom: 35px;
    }
  }
}

.cl8b {
  color: #8b8b8b;
}
</style>
