<template>
  <div class="header_page">
    <div class="header_logo">
      <!-- <router-link class="g-flex-row" to="/index">
        logo
      </router-link>-->
    </div>
    <!-- 右侧 -->
    <div class="header_nav g-flex-row" v-if="!$route.meta.anotherHeader">
      <div class="g-flex-row">
        <router-link to="/discover">
          <div
            :class="['nav_item ml20', acRouter === 'discover' ? 'active' : '']"
          >
            <span class="nav_title">发现</span>
          </div>
        </router-link>
        <!-- <router-link to="/product"> -->
        <a-popover>
          <template slot="content">
            <router-link to="/yj">
              <div
                :class="['nav_item ', acRouter === 'yj' ? 'son-active' : '']"
              >
                <span class="nav_title son_item">千川管家</span>
              </div>
            </router-link>
            <router-link to="/xc">
              <div
                :class="['nav_item ', acRouter === 'xc' ? 'son-active' : '']"
              >
                <span class="nav_title son_item">千川词霸</span>
              </div>
            </router-link>
          </template>
          <div
            :class="[
              'nav_item ml20',
              acRouter === 'yj' || acRouter === 'xc' ? 'active' : ''
            ]"
          >
            <span class="nav_title">产品</span>
          </div>
        </a-popover>
        <!-- </router-link> -->
        <router-link to="/pricing">
          <div
            :class="['nav_item ml20', acRouter === 'pricing' ? 'active' : '']"
          >
            <span class="nav_title">定价</span>
          </div>
        </router-link>
        <router-link to="/download">
          <div
            :class="['nav_item ml20', acRouter === 'download' ? 'active' : '']"
          >
            <span class="nav_title">下载</span>
          </div>
        </router-link>
        <a-popover :title="null" trigger="hover">
          <template #content>
            <div class="g-flex-col-center">
              <img src="@i/code.png" class="code" />
              <span>如有任何问题</span>
              <span>扫码添加客服微信</span>
            </div>
          </template>
          <div :class="['nav_item ml20', acRouter === 'help' ? 'active' : '']">
            <span class="nav_title">使用帮助</span>
          </div>
        </a-popover>
      </div>
      <div class="header_option ml20">
        <div v-if="token">
          <router-link to="/index">
            <a-button type="primary" class="login-button" size="small"
              >账号信息</a-button
            >
          </router-link>
        </div>
        <div v-else>
          <a-button
            type="primary"
            class="login-button"
            size="small"
            @click="onLoginOrRegister('/login')"
            >登录/注册</a-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/plugins/bus'
import Statistical from '@l/statistical'
export default {
  data () {
    return {
      acNav: 'index',
      acRouter: 'index',
      isMenuShow: false,
      mobilePhone: '',
      token: '',
      serviceList: [],
      navList: []
    }
  },
  filters: {
    phone_hide: value => {
      if (!value) {
        return ''
      }
      return value.slice(0, 3) + '****' + value.slice(7)
    }
  },
  created () {
    this.updataMobilePhone()
  },
  mounted () {
    bus.$on('updataMobilePhone', this.updataMobilePhone)
  },
  methods: {
    onUserCenter () {
      this.$router.push({ path: '/index' })
    },
    onLogOut () {
      this.$api.userAccount.reqLogout({}).then(res => {
        localStorage.removeItem('token')
        this.resetSetItem('token', '')
        localStorage.removeItem('avatarId')
        localStorage.removeItem('merchant')
        localStorage.removeItem('mobilePhone')
        localStorage.removeItem('nickname')
        localStorage.removeItem('provider')
        localStorage.removeItem('apply')
        this.token = undefined
        this.avatarId = undefined
        this.isProvider = undefined
        this.isMerchant = undefined
        this.$router.push({ path: '/login' })
      })
    },
    onClickNav (nav) {
      if (!nav.address) {
        return
      }
      this.$router.push({ path: '/' + nav.address })
      this.isMenuShow = false
      // 埋点
      const valueArr = nav.address.split('/')
      const name = valueArr[valueArr.length - 1]
      // wangQuery ranking terminal diction flowPlatform
      Statistical.href.navigation(name, nav.title)
    },
    onMouseOver (nav) {
      this.acNav = nav.name
      this.isMenuShow = true
    },
    onRouterClick (e, value) {
      // 百度统计埋点
      if (value) {
        Statistical.index[value]()
      }
      if (value === 'operate' || value === 'ranking') {
        Statistical.href.navigation[value]()
      }
      if (value === 'serviceMarket') {
        Statistical.href[value]()
      }
      // TODO还需要验证是否已经登录
      let status = '已经登录'
      if (!status) {
        return
      }
      this.$router.push({ path: '/' + e })
    },
    onPath (menu) {
      const { url, address, title } = menu
      if (url) {
        window.open(url, '_blank')
        return
      }
      if (!address) {
        this.$message.warning('即将上线,敬请期待')
        return
      }
      this.$router.push({ path: '/' + address })
      this.isMenuShow = false
      // 埋点
      const valueArr = address.split('/')
      const name = valueArr[valueArr.length - 1]
      Statistical.href.menuHead(name, title)
    },
    onLoginOrRegister (path) {
      this.$router.push({ path })
    },
    updataMobilePhone () {
      this.mobilePhone = localStorage.getItem('mobilePhone')
    }
  },
  watch: {
    $route: {
      handler (newValue, oldValue) {
        this.acRouter = newValue.meta.nav
        this.token = localStorage.getItem('token')
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.header_page {
  width: 100%;
  max-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  height: 100%;
  position: relative;
}
.header_logo {
  margin-right: 20px;
  .logo {
    width: 162px;
    height: 40px;
  }
}

.header_nav {
  .nav_item {
    // margin-right: 32px;
    font-size: 15px;
    color: #747474;
    cursor: pointer;
    line-height: 30px;
    &.active,
    &:hover {
      color: #282828;
    }
    .nav_title {
      padding: 0 10px;
    }
    &.son-active {
      // width: 100px;
      // height: 34px;
      background: #f4f6fa;
      color: #3662ad;
      margin: 0;
    }
    .son_item {
      width: 100px;
      margin: 5px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.menu_container {
  width: 100%;
  // height: 300px;
  background: #ffffff;
  // background: skyblue;
  box-shadow: 0px 6px 16px 0px #e4e4e4;
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 999;
  padding: 20px 16px;
  .menu_list {
    display: flex;
    flex-wrap: wrap;
    // background-color: yellowgreen;
    .menu_item {
      // width: 25%;
      width: 285px;
      padding: 15px;
      margin-bottom: 10px;
      // .g-flex-center;
      margin-right: 6px;
      cursor: pointer;
      &:nth-of-type(4n) {
        margin-right: 0;
      }
      &:hover {
        background-color: #eaf7ff;
        border-radius: 4px;
      }
      display: flex;
      .menu_icon {
        width: 34px;
        height: 34px;
        margin-right: 10px;
      }
      .menu_detail {
        font-size: 13px;
        color: #888888;
      }
      .menu_title {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        position: relative;
        // display: flex;
        // align-items: center;
        .icon_tag {
          width: 67px;
          height: 20px;
          position: absolute;
          right: -67px;
          top: 0px;
        }
        .icon_rt {
          position: absolute;
          right: -52px;
          top: -15px;
          width: 52px;
          height: 18px;
        }
      }
    }
  }
}

.service_container {
  display: flex;
  // justify-content: space-between;
  justify-content: space-between;
  .sv_type {
    width: 280px;
    .sv_header {
      font-size: 18px;
      color: #333333;
      position: relative;
      margin-bottom: 25px;
      padding: 0 15px;
      &:before {
        position: absolute;
        content: '';
        bottom: -6px;
        width: 24px;
        height: 3px;
        background: #3d8fff;
        border-radius: 2px;
      }
    }
    .sv_item {
      // height: 70px;
      padding: 15px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        background: #eaf7ff;
        border-radius: 4px;
      }
      .sv_icon {
        width: 34px;
        height: 34px;
        margin-right: 10px;
      }
      .sv_title {
        position: relative;
        .icon_tag {
          width: 67px;
          height: 20px;
          position: absolute;
          right: -67px;
          top: 0px;
        }
        .icon_rt {
          position: absolute;
          right: -52px;
          top: -15px;
          width: 52px;
          height: 18px;
        }
      }
    }
  }
}

.header_option {
  color: #d0d0d9;
  // width: 350px;
  text-align: right;
  .phone {
    color: #666666;
  }
  span {
    color: @cl-main;
    cursor: pointer;
  }
}
.login-button {
  height: 32px;
  border-radius: 16px;
  padding: 0 17px;
  line-height: 32px;
}
.code {
  width: 180px;
  // height: 272px;
}
</style>
