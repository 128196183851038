<template>
  <div id="container">
    <a-layout :class="$route.meta.isBgFFF ? 'bg-fff layout' : 'bg-main layout'">
      <a-layout-header class="app-header">
        <Header />
      </a-layout-header>
      <a-layout-content
        style="flex:1"
        :class="$route.meta.isMoreThen1200 ? 'wider-content relative bg-content' : 'content relative'"
      >
        <router-view />
      </a-layout-content>
      <a-layout-footer v-if="!$route.meta.withoutFooter">
        <Footer @applicationClick="onApplicationClick" />
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
import Statistical from '@l/statistical'
import regular from '@l/regular'
import Footer from '@/views/layout/Footer.vue'
import Header from '@/views/layout/Header.vue'
import { mapState, mapMutations } from 'vuex'
export default {
  components: { Footer, Header },
  computed: {
    ...mapState(['XImgQRCode'])
  },
  created () {
    var token = localStorage.getItem('token')
    this.token = localStorage.getItem('token')
    // console.log(this.$route)
    if (token) {
      // this.getUser()
    } else {
      this.setUserInfo()
    }
    // this.getQRCode()
    // this.getUserIntegral()
  },
  destroyed () {
    clearInterval(this.paytimer)
  },
  beforeUpdate () {
    this.token = localStorage.getItem('token')
  },
  methods: {
    ...mapMutations(['setImgQRCode']),
    // ------------------ 点击方法
    handleCancel () {
      this.showModal = false
      this.showMerchantInfo = false
    },
    onSuccess () {
      this.showMerchantInfo = false
    },
    onCancelEidtClick () {
      this.params.applicantMobilePhone.value = localStorage.getItem(
        'mobilePhone'
      )
      this.isEditPhone = false
    },
    onMerchantStateClick () {
      Statistical.index.businessbCenter()
      this.token = localStorage.getItem('token')
      if (!this.token) {
        // this.$message.info('请先登录')
        // this.$router.push({ path: '/login' })
        this.loginShow = true
      }
    },
    handleOk () {
      if (!this.params.applicantName.value) {
        this.params.applicantName.help = '请填写联系人'
        return
      }
      if (
        this.params.applicantName.value &&
        !regular.regChineseEnglish16.test(this.params.applicantName.value)
      ) {
        this.params.applicantName.help = '请输入正确格式的联系人'
        return
      }
      this.params.applicantName.help = ''
      if (
        !this.params.applicantMobilePhone.value &&
        this.params.applicantMobilePhone.value !== 0
      ) {
        this.params.applicantMobilePhone.help = '联系电话不能为空'
        return
      }
      if (
        this.params.applicantMobilePhone.value &&
        !regular.regPhone.test(this.params.applicantMobilePhone.value)
      ) {
        this.params.applicantMobilePhone.help = '请输入正确格式的联系电话'
        return
      }
      if (this.isEditPhone && !this.params.applicantMobilePhone.captcha) {
        this.params.applicantMobilePhone.help = '请填写验证码'
        return
      }
      if (
        this.params.applicantMobilePhone.captcha &&
        !regular.regCaptcha.test(this.params.applicantMobilePhone.captcha)
      ) {
        this.params.applicantMobilePhone.help = '请输入正确格式的验证码'
        return
      }
      this.params.applicantMobilePhone.help = ''
      if (this.isEditPhone) {
        this.checkCaptcha()
      } else {
        this.addApplication()
      }
    },
    onEidtClick () {
      this.isEditPhone = true
    },
    onCaptchaClick () {
      if (
        !this.params.applicantMobilePhone.value &&
        this.params.applicantMobilePhone.value !== 0
      ) {
        this.params.applicantMobilePhone.help = '联系电话不能为空'
        return
      }
      if (
        this.params.applicantMobilePhone.value &&
        !regular.regPhone.test(this.params.applicantMobilePhone.value)
      ) {
        this.params.applicantMobilePhone.help = '请输入正确格式的联系电话'
        return
      }
      this.params.applicantMobilePhone.help = ''
      this.sendCaptcha()
    },
    onApplicationClick () {
      this.setUserInfo()
      if (!this.token) {
        // this.$message.warning('请先登录')
        // this.$router.push({ path: '/login' })
        this.loginShow = true
        return
      }
      Statistical.index.serviceprovider()
      this.getApplication()
    },
    onRouterClick (e, value) {
      // 百度统计埋点
      if (value) {
        Statistical.index[value]()
      }
      if (value === 'operate' || value === 'ranking') {
        Statistical.href.navigation[value]()
      }
      if (value === 'serviceMarket') {
        Statistical.href[value]()
      }
      // TODO还需要验证是否已经登录
      let status = '已经登录'
      if (!status) {
        return
      }
      this.$router.push({ path: '/' + e })
    },
    // ------------------ 请求方法
    getUserIntegral () {
      this.$api.flowTaskCenter.getUserIntegral({}).then(res => {
        this.userIntegral = res.integral
        this.$store.commit('undataIntegral', res.integral)
      }).catch(err => {
        console.log(err)
      })
    },
    sendCaptcha () {
      this.$api.mobileCaptcha
        .sendCaptcha({ mobilePhone: this.params.applicantMobilePhone.value })
        .then(res => {
          this.countdown()
        })
        .catch(res => {
          if (!res) {
            // 非正常报错不提示用户
            return
          }
          this.$message.error('请求过于频繁,请稍后再试')
        })
    },
    // 校验验证码
    checkCaptcha () {
      var param = {
        mobilePhone: this.params.applicantMobilePhone.value,
        captcha: this.params.applicantMobilePhone.captcha
      }
      this.$api.mobileCaptcha
        .checkCaptcha(param)
        .then(res => {
          this.addApplication()
        })
        .catch(res => {
          if (!res) {
            // 非正常报错不提示用户
            return
          }
          this.$message.error('验证码错误')
        })
    },
    addApplication () {
      var param = {
        applicantMobilePhone: this.params.applicantMobilePhone.value,
        applicantName: this.params.applicantName.value
      }
      this.$api.providerEnter
        .addApplication(param)
        .then(res => {
          this.showModal = false
        })
        .catch(res => {
          if (!res) {
            // 非正常报错不提示用户
            return
          }
          this.$message.error('服务器异常,请稍后重试')
        })
    },
    getApplication () {
      this.$api.providerEnter.getApplication({}).then(res => {
        console.log(res)
        if (res.isEnter) {
          this.$router.push({ path: '/serviceProvider' })
          return
        }
        if (res.isApply) {
          this.$message.info('您的申请正在审核中')
          return
        }
        this.showModal = true
        this.params.applicantMobilePhone.value = localStorage.getItem(
          'mobilePhone'
        )
      })
    },
    // ------------------ 其它
    setUserInfo () {
      this.token = localStorage.getItem('token')
      this.avatarId = localStorage.getItem('avatarId')
      this.isProvider = localStorage.getItem('provider') === 'true'
      this.isMerchant = localStorage.getItem('merchant') === 'true'
      this.isApply = localStorage.getItem('apply') === 'true'
      console.log(this.isApply)
    },
    countdown () {
      const _this = this
      if (this.waitTime === 0) {
        this.waitTime = 60
      } else {
        this.waitTime--
        setTimeout(function () {
          _this.countdown()
        }, 1000)
      }
    },
    closeLogin () {
      this.loginShow = false
    },
    // 埋点
    register () {
      Statistical.login.register()
    }
  },
  // computed: {
  //   isFinish () {
  //     return `${this.province}${this.city}${this.area}`
  //   }
  // },
  data () {
    return {
      waitTime: 60,
      showMerchantInfo: false,
      params: {
        applicantMobilePhone: {
          value: '',
          help: '',
          captcha: ''
        },
        applicantName: {
          value: '',
          help: ''
        }
      },
      confirmLoading: false,
      isEditPhone: false,
      showModal: false,
      token: undefined,
      avatarId: undefined,
      isProvider: false,
      isMerchant: false,
      isApply: false,
      loginShow: false
    }
  }
}
</script>
<style lang="less" scoped>
@import "index";
</style>
